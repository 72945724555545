import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import RoutesPage from './routing/RoutePage'
import { ToastContainer } from 'react-toastify'
import './i18n';
import { AuthProvider } from './custom-hooks';
function App() {

  return (
    <BrowserRouter>
      <RoutesPage />
      <ToastContainer />
    </BrowserRouter>
  )
}

export default App