import React from 'react';
import NavbarLogo from "../../assets/images/Arrow.svg";
import { Link } from 'react-router-dom';

const Navbar = () => {
    const userObject=JSON.parse(localStorage.getItem("userObj"));
    return (
        <div className="flex bg-white p-2 w-full justify-between items-center">
            <img src={NavbarLogo} className='w-14 m-1' />
            <div><p className='text-black text-xs font-bold'>Welcome Back!</p></div>
            <div><p className='text-black text-xs font-bold'>User ID:{userObject?.user_id}</p></div>
            <div className='flex justify-between'>
                <div className='border border-blue-500 rounded p-2 hover:bg-blue-200'><p className='text-black text-xs font-normal '>Hello,{userObject?.first_name}</p></div>
                <Link to="/">
                    <button className='border rounded border-blue-500 hover:bg-blue-200 text-xs p-2 ml-2 text-black'>Logout</button>
                </Link>
            </div>
        </div>
    );
};

export default Navbar;
