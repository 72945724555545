import { axiosInstance } from "./axiosInstance";
import appConfig from "../app.config";


let URL = `${appConfig.baseUrl}`


export async function apiGetGrievance() {
    return axiosInstance.get(`${URL}/grievances/get_category`);
}

export async function apiGetDistrict(id) {
    // console.log("PPPPPPPPPPP", id);
    return axiosInstance.get(`${URL}/dashboard/district?state_id=${id}`);
}

export async function apiGetArea(data) {
    return axiosInstance.get(`${URL}/dashboard/constituency?district_id=${data}`);
}