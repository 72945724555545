import React, { useState } from 'react';
import "./index.css";

const Sidebar = ({ changeContent }) => {

    const menuItems = [
        // 'Home',
        'Grievance Received',
        // 'Manage Content',
        'Help',
    ];

    const [selectedItem, setSelectedItem] = useState(0);

    const handleItemClick = (index) => {
        setSelectedItem(index);
        changeContent(index);
    };

    return (
        <div className="bg-white text-black w-1/5">
            <ul>
                {menuItems.map((item, index) => (
                    <li
                        key={index}
                        className={`cursor-pointer py-2 ${selectedItem === index ? 'active-tab p-3' : 'p-3'}`}
                        onClick={() => handleItemClick(index)}
                    >
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
