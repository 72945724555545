import React from 'react'
import { Link } from 'react-router-dom'
import greenTick from '../../../assets/images/greenTick.svg'

function ResponsePage() {
    return (
        <div class="h-56 grid grid-cols-1 mt-40 gap-4 content-center ...">

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 ml-0 mr-0 text-center mt-0 mb-8">
                <div className="px-5 py-5 rounded-lg mt-8 ">
                    <div className="p-0">

                    </div>
                </div>

                <div className="px-5 py-5 rounded-lg bg-lime-100 mt-8 ">
                    <div className="p-0">
                        <div className='flex justify-center items-center'>
                            <img
                                src={greenTick}
                                alt="Something Went Wrong"
                                width={50}
                                height={50}
                            />
                        </div>
                        {/* <h1>Your Grievance has been submitted Successfully soon you will get the update On your registered mobile number.</h1> */}
                        <h1>Your grievance has been submitted successfully. You will soon receive an update on your registered mobile number.</h1>
                        <div className='mt-4'>
                            <Link to={'/'} className='mt-4'>
                                <a href="#" className="inline-block text-md px-4 py-2 w-48 leading-none border rounded text-lime-700 border-lime-700 hover:border-transparent hover:text-slate-50 hover:bg-lime-700 mt-0 lg:mt-0">Back to Home</a>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="px-5 py-5 rounded-lg mt-8 ">
                    <div className="p-0">

                    </div>
                </div>

            </div>
        </div>
    )
}

export default ResponsePage