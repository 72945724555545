import React from 'react'

function Footer() {
    return (
        <>


            <footer className="bg-gray-200 dark:bg-gray-900">
                <div className="mx-auto w-full max-w-screen-xl">
                    <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
                        <div>
                            <h2 className="mb-6 text-lg font-semibold text-gray-900 uppercase dark:text-white">JANTA DAL</h2>
                            <ul className="text-gray-500 dark:text-gray-400 font-medium text-sm">
                                <li className="mb-4">
                                    <a href="#" className=" hover:underline">About us</a>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h2 className="mb-6 text-lg font-semibold text-gray-900 uppercase dark:text-white">JOIN US</h2>
                            <ul className="text-gray-500 dark:text-gray-400 font-medium text-sm">
                                <li className="mb-4">
                                    <a href="#" className="hover:underline">Join us</a>
                                </li>
                                <li className="mb-4">
                                    <a href="#" className="hover:underline">Donation</a>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h2 className="mb-6 text-lg font-semibold text-gray-900 uppercase dark:text-white">ADDRESS</h2>
                            <ul className="text-gray-500 dark:text-gray-400 font-medium text-sm">
                                <li className="mb-4">
                                    <a href="#" className="hover:underline">Address : Veerchand Patel Road Area, Patna, Bihar 800001</a>
                                </li>
                                <li className="mb-4">
                                    <a href="#" className="hover:underline">Phone : 0612 222 8055</a>
                                </li>
                                <li className="mb-4">
                                    <a href="#" className="hover:underline">Emergency Email : jduofficial123@gmail.com</a>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h2 className="mb-6 text-lg font-semibold text-gray-900 uppercase dark:text-white">CONTACT DETAILS</h2>
                            <ul className="text-gray-500 dark:text-gray-400 font-medium text-sm">
                                <li className="mb-4">
                                    <a href="https://www.facebook.com/AshokChoudhaary/" target="_blank" className="hover:underline">Facebook</a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FAshokChoudhaary" target="_blank" className="hover:underline">Twitter</a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://www.instagram.com/ashokchoudhaary/?hl=en" target="_blank" className="hover:underline">Instagram</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className="px-4 py-3 bg-gray-100 dark:bg-gray-700 md:flex md:items-center md:justify-between">
                        <span className="text-sm text-gray-500 dark:text-gray-300 sm:text-center">© 2023 <a href="https://flowbite.com/">JDU(United)</a>. All Rights Reserved.
                        </span>
                        <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
                            <a href="https://www.facebook.com/AshokChoudhaary/" target="_blank" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                                    <path fill="#3f51b5" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M29.368,24H26v12h-5V24h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H30v4h-2.287 C26.104,16,26,16.6,26,17.723V20h4L29.368,24z"></path>
                                </svg>
                            </a>
                            <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FAshokChoudhaary" target="_blank" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                                    <path fill="#212121" fill-rule="evenodd" d="M38,42H10c-2.209,0-4-1.791-4-4V10c0-2.209,1.791-4,4-4h28	c2.209,0,4,1.791,4,4v28C42,40.209,40.209,42,38,42z" clip-rule="evenodd"></path><path fill="#fff" d="M34.257,34h-6.437L13.829,14h6.437L34.257,34z M28.587,32.304h2.563L19.499,15.696h-2.563 L28.587,32.304z"></path><polygon fill="#fff" points="15.866,34 23.069,25.656 22.127,24.407 13.823,34"></polygon><polygon fill="#fff" points="24.45,21.721 25.355,23.01 33.136,14 31.136,14"></polygon>
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/ashokchoudhaary/?hl=en" target="_blank" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                                    <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"></stop><stop offset=".328" stop-color="#ff543f"></stop><stop offset=".348" stop-color="#fc5245"></stop><stop offset=".504" stop-color="#e64771"></stop><stop offset=".643" stop-color="#d53e91"></stop><stop offset=".761" stop-color="#cc39a4"></stop><stop offset=".841" stop-color="#c837ab"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"></stop><stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer