import React, { useRef, useEffect } from "react";
import picTwo from "../../../src/assets/images/picTwo.png";
import Arrow from "../../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Nav from "./Nav";
import ImageSlider from "../../components/ImageSlider";
import ImageSlider1 from "../../components/ImageSlider1";
import ImageSlider2 from "../../components/ImageSlider2";
import Footer from "../../components/Footer";

function LadingPage() {

  const targetRef = useRef();
  const targetRef2 = useRef();
  const location = useLocation();

  useEffect(() => {
    if (location.search.includes('scroll=true1') && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
      console.log(targetRef.current)
    }
    else if (location.search.includes('scroll=true2') && targetRef.current) {
      targetRef2.current.scrollIntoView({ behavior: 'smooth' });
      console.log(targetRef2.current)
    }
  }, [location.search]);



  return (
    <>
      {/* <div className="text-center mt-36 ml-0 mx-auto">
                <img src={picTwo} alt="Your Image" class="rounded-full w-1/5 h-full ml-24" />
            </div> */}
      {/* <div className="bg-white text-black container mx-auto">
        <div className="">
          <img src={Arrow} alt="Your Image" class="rounded-full w-16 h-12 " />
          <p className="text-center text-3xl mt-2 py-1">Dr. Ashok Choudhary</p>
          <p className="text-center text-zinc-400">
            Member of the Bihar Legislative Council
          </p>
        </div>
      </div> */}

      <div className="grid grid-cols-1 gap-16 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3  2xl:grid-cols-3 ml-0 2xl:mr-24 text-center mt-0 mb-0 py-2">
        <div></div>
        <div className="flex justify-center gap-4">
          <img src={Arrow} alt="Your Image" className="rounded-full w-20 h-16 mt-0" />
          <div>
            <p className="text-center text-3xl mt-0 py-0">Dr. Ashok Choudhary</p>
            <p className="text-center text-zinc-400">
              Member of the Bihar Legislative Council
            </p>
          </div>
        </div>
        <div></div>
      </div>
      <hr />
      <Nav />

      <div>
        <div className="flex flex-col min-h-screen">
          <div className="grid grid-cols-1 gap-0 mb-0 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 ml-0 mr-0 mt-0 bg-amber-300">
            <div className="px-0 py-0 mt-0 ">
              <div className="ml-1 mt-1 px-5 py-5"></div>
              <br />

              <div className="text-center mt-10 ml-0 mx-auto ">
                <img
                  src={picTwo}
                  alt="Your Image"
                  class="rounded-full w-3/5 h-full ml-24"
                />
              </div>
            </div>

            <div className="px-8 py-52  mt-0  text-black ">
              <div className="text-center mt-0 ">
                <div className="font-semibold text-3xl text-gray-800">
                  Progressive Bihar
                </div>
              </div>

              <div className=" py-0 mt-8 text-2xl">
                I am proud to be a Bihari, where history, culture and tradition
                are deeply embedded in our roots.
              </div>
            </div>
          </div>
          <div className=" bg-red-100 flex justify-center w-100 text-center flex-col p-10" ref={targetRef} >
            <h1 className="font-bold p-3 text-2xl ">Dr. Ashok Choudhary</h1>
            <h3 className="contents text-lg">

              Ashok Choudhary is an Indian politician from Barbigha Sheikhpura
              in Bihar, India. He has been a member of the Bihar Legislative
              Council since 2014. Since June 2019, he is the Cabinet Minister
              for the Building Construction Department in the government led by
              Nitish Kumar. He previously served as Cabinet Minister for the
              Department of Education. He was appointed to the position of
              Working President of Janta Dal United, Bihar.
            </h3>
          </div>
          <div className="bg-neutral-200 flex flex-col justify-center w-100 text-center p-10 " ref={targetRef2} >
            <div class="font-bold p-3 text-2xl">Sheikhpura</div>
            <div class="contents text-lg">
              Sheikhpura district is one of the thirty-eight districts of the
              Indian state of Bihar. Sheikhpura is the administrative
              headquarter of this district. Sheikhpura district is a part of the
              Munger division. Sheikhpura was split from the Munger district
              into a separate district with headquarters in Sheikhpura on 31
              July 1994 by Rajo Singh. As of 2011 it was the least populous
              district of Bihar.
            </div>
          </div>


          {/* <div className=" grid grid-cols-2 gap-0 mb-0 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2"> */}


          <div className=" flex justify-between ">

            <div className="px-4 py-10 mt-0 ">
              <ImageSlider />
            </div>

            <div className="px-4 py-10  mt-0 ">
              <ImageSlider1 />
            </div>


            <div className="px-4 py-10 mt-0">
              <ImageSlider2 />
            </div>
          </div>





          <div className="flex-grow"></div>

          <div>
            <Footer />
          </div>




          {/* <div className="flex-grow"></div> */}

          {/* <footer className="bg-green-800 text-white py-0">
            <div className="container mx-auto text-center">
              &copy; 2023 Janta Dal(United). All Rights Reserved.
            </div>
          </footer> */}
        </div>
      </div>
    </>
  );
}

export default LadingPage;
