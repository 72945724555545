
import { axiosInstance } from "./axiosInstance";
import appConfig from "../app.config";

let URL = `${appConfig.baseUrl}`

export async function apiVerifyAdhar(data) {
    return axiosInstance.post(`${URL}/verification/adhaar_verify`, data);
}
export async function apiVerifyAdharAuth(data) {
    return axiosInstance.post(`${URL}/verification/fetch_details`, data);
}