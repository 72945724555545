import React from "react";
import Navbar from "../../components/common_components/Navbar";
import Nitesh from "../../assets/images/Nitesh.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import * as Yup from "yup";
import { apiGetTicket } from "../../services/GetTicket";
import { useTranslation } from "react-i18next";
import picTwo from "../../../src/assets/images/picTwo.png"

const TrackGrievance = () => {
    const { t } = useTranslation();

    const navigation = useNavigate();

    const [grievanceData, setGrievanceData] = useState([]);
    const [errorStatus,setErrorStatus]=useState("");
    // console.log("Response data ::", grievanceData);
    // console.log("Response data ::", grievanceData?.data?.comments);

    const description = grievanceData?.data?.data.description;
    const mobile_number = grievanceData?.data?.data.mobile_number;
    const email = grievanceData?.data?.data.email;
    const created_date = grievanceData?.data?.data.created_date;
    const tracking_number = grievanceData?.data?.data.tracking_number;
    const status = grievanceData?.data?.data.status;
    const comments = grievanceData?.data?.comments;
    // console.log(">>>>>>>>>>>", comments);

    const initialValues = {
        tracking_number: "",
    };

    // console.log(initialValues.district);

    const validationSchema = Yup.object({
        tracking_number: Yup.string().required("Grievance Id is required"),
    });

    const handleSubmit = async (values) => {
        const { tracking_number } = values;
        // console.log("ID", values);

        const fetchData = async () => {
            try {
                const response = await apiGetTicket(tracking_number);
                setGrievanceData(response);

                if (response?.request?.status === "200") {
                    // navigation("/admin")
                }
            } catch (error) {
                setErrorStatus(error?.response?.data?.data);
                // setGrievanceData(error.response.data);
                
            }
        };
        fetchData();
    };

    return (
        <>
            {/* <div className="background_container inset-y-0 "> */}
            <div className="flex flex-col min-h-screen bg-amber-300">
                <div className="mt-0">
                    <Navbar />
                </div>



                <div className="mt-0 py-16">
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
                        <div>
                            <div className="flex items-center justify-center">
                                {/* <img className="mt-0" src={picTwo} alt="Something Went Wrong" width={400} height={600} /> */}
                                <img
                                    src={picTwo}
                                    alt="Your Image"
                                    class="rounded-full w-140 h-full"
                                />
                            </div>
                        </div>

                        {/* <div className="bg-green-600 flex items-center justify-center"> */}
                        <div className="px-5 flex items-top justify-center" >
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                <Form className="mt-12">
                                    <div className="mb-0 px-0 text-black">
                                        <label
                                            htmlFor="tracking_number"
                                            className="block text-gray-600 text-md text-semibold font-medium mb-1 text-black"
                                        >
                                            {t("trackgrievance_id")}
                                        </label>
                                        <Field
                                            type="text"
                                            id="tracking_number"
                                            name="tracking_number"
                                            className="w-96 px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-blue-500 "
                                            // placeholder="Search Id"
                                            placeholder={t("search_id")}
                                        />
                                        <ErrorMessage
                                            name="tracking_number"
                                            component="p"
                                            className="text-red-500 text-sm mt-0"
                                        />
                                    </div>

                                    <div
                                        className="mt-6 px-0"
                                        data-modal-target="staticModal"
                                        data-modal-toggle="staticModal"
                                    >
                                        <button
                                            type="submit"
                                            className="w-96 px-4 py-2 bg-green-800 text-white rounded hover:bg-green-900 focus:outline-none"
                                            onClick={() =>
                                                document.getElementById("my_modal_3").showModal()
                                            }
                                        >
                                            {t("search")}
                                        </button>

                                        {/* <button data-modal-target="staticModal" data-modal-toggle="staticModal" className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                        Toggle modal
                                    </button> */}

                                        {/* <div id="staticModal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"> */}
                                        <div>
                                            <dialog id="my_modal_3" className="modal ">
                                                <div className="modal-box w-8/12 max-w-3xl bg-white text-black ">
                                                    {/* <div className="relative bg-white rounded-lg shadow dark:bg-gray-700"> */}
                                                    <div className="flex items-start justify-between p-4 border-b rounded-t ">
                                                        <h3 className="text-xl font-semibold text-gray-900">
                                                            {/* Grievance Record */}
                                                            {t("grievance_record")}

                                                        </h3>

                                                        <form method="dialog">
                                                            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                                                                ✕
                                                            </button>
                                                        </form>
                                                    </div>

                                                    <div className="p-6 space-y-6 space-x-4 ">
                                                        {
                                                            errorStatus?<div>{errorStatus}</div>:
                                                            <>
                                                                <div>
                                                                    <p>
                                                                        {/* Tracking Number :{" "} */}
                                                                        {t("record_tracking_number")}:{" "}
                                                                        <strong>{tracking_number}</strong>
                                                                    </p>
                                                                    <p>
                                                                    {t("record_phone_number")} :{" "}
                                                                        <strong>{mobile_number}</strong>
                                                                    </p>
                                                                    <p>
                                                                        {/* Description : <strong>{description}</strong> */}
                                                                        {t("record_description")}: <strong>{description}</strong>
                                                                    </p>
                                                                    <p>
                                                                    {t("record_email")} : <strong>{email}</strong>
                                                                    </p>
                                                                    <p>
                                                                    {t("record_created_code")} : <strong>{created_date}</strong>
                                                                    </p>
                                                                    <p>
                                                                    {t("record_tracking_status")} : <strong>{status}</strong>
                                                                    </p>
                                                                </div>
                                                                <hr />

                                                    <div class="flex flex-col bg-gray-400 rounded bg-white text-black">
                                                        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                            <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                                                {/* <div class="overflow-hidden"> */}
                                                                {/* <table class="min-w-full text-left text-sm font-light"> */}
                                                                <table className="min-w-full text-cnter">
                                                                    <thead class="border-b font-medium min-w-full">
                                                                        <tr>
                                                                            {/* <th scope="col" class="px-6 py-4">Sl.</th>
                                                                            <th scope="col" class="px-6 py-4">Comment Date</th>
                                                                            <th scope="col" class="px-6 py-4">Comments</th>
                                                                            <th scope="col" class="px-6 py-4">Status</th> */}

                                                                            <th scope="col" class="px-6 py-4">{t("grievance_record_si")}</th>
                                                                            <th scope="col" class="px-6 py-4">{t("grievance_record_comment_date")}</th>
                                                                            <th scope="col" class="px-6 py-4">{t("grievance_record_comments")}</th>
                                                                            <th scope="col" class="px-6 py-4">{t("grievance_record")}</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {comments?.map((item, i) => {
                                                                            return (
                                                                                <>
                                                                                    <tr key={i} className="text-center border-b hover:bg-gray-300" >
                                                                                        <td>{i + 1}</td>
                                                                                        <td>{item.comment_date}</td>
                                                                                        <td>{item.comment}</td>
                                                                                        <td>{status}</td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                                {/* </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                            </>
                                                        }
                                                    </div>




                                                    {/* </div> */}
                                                </div>
                                            </dialog>
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
};
export default TrackGrievance;
