import React, { useState, useEffect } from "react";
import Navbar from "../../components/common_components/Navbar";
import picTwo from "../../assets/images/picTwo.png";
import { useTranslation } from "react-i18next";
import { apiActionPlans } from "../../services/MenuServices";

const ActionPlan = () => {
    const { t } = useTranslation();

    const [actionPlan, setActionPlan] = useState("");

    // API get Action Plans
    useEffect(() => {
        agetApiActionPlans();
    }, []);

    const agetApiActionPlans = async () => {
        try {
            const response = await apiActionPlans();
            // console.log("Table Data ::", response?.data?.data?.grievance_category?.grievance_name);
            console.log("Action Plan ::", response);

            // setActionPlan(response.data.comments);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div className="flex flex-col min-h-screen bg-amber-300">
                <div className="mt-0">
                    <Navbar />
                </div>

                {/* Main div */}
                <div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 ml-0 mr-0 mt-0 mb-8 py-8 px-5 mt-8 text-black">
                        <div>
                            <div className="flex items-center justify-center">
                                {/* <img className="mt-0" src={picTwo} alt="Something Went Wrong" width={400} height={600} /> */}
                                <img
                                    src={picTwo}
                                    alt="Your Image"
                                    class="rounded-full w-140 h-full"
                                />
                            </div>
                        </div>

                        <div>
                            <h1 className="text-2xl">{t("action_plans")}</h1>
                            <div class="m-2 flex flex-wrap"></div>

                            <p class="ml-0 mx-16">
                                {/* {t("action_paragraph")} */}
                                {actionPlan}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ActionPlan;
