import { axiosInstance } from "./axiosInstance";
import appConfig from "../app.config";


let URL = `${appConfig.baseUrl}`


export async function apiGetTicket(id) {
    // return axiosInstance.get(`${URL}/grievances/tracking?tracking_number=${id}`);

    return axiosInstance.get(`${URL}/grievance/tracking?tracking_number=${id}`);

}