import React, { useEffect, useState, useMemo } from "react";
// import { Select } from "components/ui";
// import Loading from "components/shared";

const Paginataion = ({
    dataCount,
    pageSize,
    currentPage,
    changeCurrentPage,
    onSelectChange,
    pagingData,
    pageSizes,
    loading,
    pageSizeController,
}) => {
    const [displayPageNumber, setDisplayPageNumber] = useState([]);

    const totalPages = Math.ceil(dataCount / pageSize);
    // console.log(totalPages,'totalPages')
    let pageNumbers = [];
    if (!Number.isNaN(totalPages)) {
        pageNumbers = [...Array(Math.max(0, totalPages + 1)).keys()].slice(1);
    }

    const nextPage = () => {
        if (currentPage < pageNumbers?.length) {
            changeCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            changeCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        let lastSevenPage = totalPages - 7;
        if (pageNumbers?.length > 0) {
            let start = 0;
            let end = currentPage + 6;
            if (totalPages > 6) {
                start = currentPage - 1;

                if (parseInt(lastSevenPage) <= parseInt(start)) {
                    start = lastSevenPage;
                }
            }
            const pageNumber = pageNumbers.slice(start, end)?.map((pgNumber, i) => {
                return pgNumber;
            });
            setDisplayPageNumber(pageNumber);
        }
    }, [currentPage, totalPages]);
    const pageSizeOption = useMemo(
        () =>
            pageSizes.map((number) => ({
                value: number,
                label: `${number} / page`,
            })),
        [pageSizes]
    );
    const handleSelectChange = (value) => {
        if (!loading) {
            onSelectChange?.(Number(value));
        }
    };

    return (
        <>
            {" "}
            <div className="flex justify-end items-center mt-2">
                <ul className="pagination text-center">
                    <li className="page-item">
                        <button
                            className="cursor-pointer py-2 px-4 rounded-l"
                            onClick={prevPage}
                        >
                            <svg
                                aria-hidden="true"
                                class="w-4 h-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </li>

                    {displayPageNumber?.map((pgNumber, i) => (
                        <li
                            key={i}
                            className={
                                pgNumber === currentPage
                                    ? "cursor-pointer px-3 py-2 text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:bg-indigo-700 dark:text-white"
                                    : "cursor-pointer px-3 py-2 leading-tight text-indigo-500 bg-white hover:bg-indigo-100 hover:text-indigo-700 dark:bg-indigo-800 dark:border-indigo-700 dark:text-indigo-400 dark:hover:bg-indigo-700 dark:hover:text-white"
                            }
                            onClick={() => changeCurrentPage(pgNumber)}
                        >
                            <a href={() => false} className={`page-link data_${i}`}>
                                <span>{pgNumber}</span>
                            </a>
                        </li>
                    ))}

                    <li className="page-item">
                        <button
                            className="cursor-pointer py-2 px-4 rounded-1"
                            onClick={nextPage}
                            disabled={currentPage === pageNumbers[pageNumbers?.length - 1]}
                        >
                            <svg
                                aria-hidden="true"
                                class="w-4 h-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </li>
                </ul>
                {pageSizeController && (
                    <div style={{ minWidth: 130 }}>
                        {/* <Select */}
                        <select>
                            size="sm"
                            menuPlacement="top"
                            isSearchable={false}
                            value={pageSizeOption.filter(
                                (option) => option.value == pagingData
                            )}
                            options={pageSizeOption}
                            onChange={(option) => handleSelectChange(option.value)}
                        </select>
                    </div>
                )}
            </div>
        </>
    );
};
Paginataion.defaultProps = {
    pageSizes: [10, 20, 50, 100],
};
export default Paginataion;
