import React, { useState, useEffect } from "react";
import Navbar from "../../components/common_components/Navbar";
import picTwo from "../../assets/images/picTwo.png";
import { useTranslation } from "react-i18next";
import { apiAboutUs } from "../../services/MenuServices";

const AboutUs = () => {

  const { t } = useTranslation();

  const [aboutUs, setAboutUs] = useState([]);
  const [aboutUsTitle, setAboutUsTitle] = useState([]);


  // API get about us data
  useEffect(() => {
    getAboutUsData();
  }, []);

  const getAboutUsData = async () => {
    try {
      const response = await apiAboutUs();
      console.log("About Us ::", response?.data?.data);

      const apiData = response?.data?.data
      // console.log("ooooooooooooo", apiData[0]);
      // console.log("ooooooooooooo", apiData);

      const apiDescription = apiData[0].description

      setAboutUs(apiDescription);

    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* <div className="background_container inset-y-0 "> */}
      <div className="flex flex-col min-h-screen bg-amber-300">
        <div className="mt-0">
          <Navbar />
        </div>

        {/* Main div */}
        <div>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 ml-0 mr-0 mt-0 mb-8 py-8 px-5 mt-8 text-black ">
            <div>
              <div className="flex items-center justify-center">
                {/* <img className="mt-0" src={picTwo} alt="Something Went Wrong" width={400} height={600} /> */}
                <img
                  src={picTwo}
                  alt="Your Image"
                  class="rounded-full w-140 h-full"
                />
              </div>
            </div>

            <div>
              <h1 className="text-2xl">
                {/* About Us */}
                {t("about_us")}
              </h1>
              <div class="m-2 flex flex-wrap"></div>

              <p class="ml-0 mx-16">
                {/* {t('about_paragraph')} */}
                {aboutUs}
              </p>

              {/* {aboutUs.map((e) => {
                <div>
                  <p>{e.description}</p>
                </div>
              })} */}


            </div>

          </div>
        </div>
      </div>
    </>
  );
};
export default AboutUs;
