import React from 'react';
import { useTranslation } from 'react-i18next';

function Dropdown() {
    const { i18n } = useTranslation();

    const changeLanguage = (event) => {
      const selectedLanguage = event.target.value;
      i18n.changeLanguage(selectedLanguage);
    };
  

    return (

        <div className='flex justify-center items-center w-40'>
            {/* <label htmlFor="selectedOption" className="block text-gray-600 text-sm font-medium mb-2">
                Grievance Category
            </label> */}
            <select
                id="selectedOption"
                name="selectedOption"
                className=" px-1 py-1 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                onChange={changeLanguage}
                 defaultValue={i18n.language}
            // value={formData.selectedOption}
            // onChange={handleSelectChange}
            >
                <option value="hi">Hindi </option>
                <option value="en">English</option>
                {/* <option value="bh">Bhojpuri</option> */}

            </select>
        </div>
    )
}

export default Dropdown