import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { apiGetGrievanceList } from "../../services/GrievanceServices";
import Paginataion from "../../components/common_components/Pagination";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { apiAddComment } from "../../services/AddComment";
import { apiGetTicket } from "../../services/GetTicket";
import { toast } from "react-toastify";
import { apiStatusUpdate } from "../../services/GrievanceServices";


const GrievanceTable = () => {
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [grievanceId, setGrievanceId] = useState("");
  const [comments, setComments] = useState([]);
  const [trackingId, setTrackingId] = useState("");
  const [grievanceCategory, setGrievanceCategory] = useState("")
  // console.log(grievanceId);

  // console.log("Table Data ::", data);



  const statusUpdate = async (status) => {
    // console.log(status)
    try {
      const data = {
        "status": status,
        "grievance_id": grievanceId
      }
      const response = await apiStatusUpdate(data);
      // console.log("Status update response", response);
      fetchGrievanceList();
    } catch (err) {
      console.log(err);
    }
  }



  localStorage.setItem("grievanceList", JSON.stringify(data));

  const changeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchGrievanceList();
  }, [currentPage]);

  const userObject = JSON.parse(localStorage.getItem("userObj"));
  const districtId = userObject?.district?.district_id;

  const fetchGrievanceList = async () => {
    try {
      const data = {
        currentPage: currentPage,
      };
      const response = await apiGetGrievanceList(districtId, data);
      // console.log("Data ::", response);
      setData(response?.data?.data);
      setTotalItems(response?.data?.count);
    } catch (err) {
      console.log(err);
    }
  };

  const initialValues = {
    grievance_id: "",
    comment: "",
    comment_by: "",
  };

  // console.log(initialValues.district);

  const validationSchema = Yup.object({
    // comment: Yup.string().required("comment is required"),
  });

  // Get comment
  useEffect(() => {
    getComments();
  }, [trackingId]);
  const getComments = async () => {
    try {
      const response = await apiGetTicket(trackingId);
      // console.log("Table Data ::", response?.data?.data?.grievance_category?.grievance_name);
      setGrievanceCategory(response?.data?.data?.grievance_category?.grievance_name)
      setComments(response.data.comments);
    } catch (err) {
      console.log(err);
    }
  };
  // console.log(comments, "--comments");
  // console.log("GrievanceCategory ::", grievanceCategory);

  const handleSubmit = async (values, { resetForm }) => {
    // console.log("llllllllll", values);
    const { comment } = values;

    const userObjectOne = JSON.parse(localStorage.getItem("userObj"));
    // console.log("Usaer1", userObjectOne);
    // console.log("Usaer2", userObjectOne?.user_id);
    const comment_by = userObjectOne?.user_id;
    // console.log("Datasdf", comment_by);


    const grievance_id = grievanceId;

    const fetchData = async () => {
      try {
        const response = await apiAddComment({
          comment_by,
          comment,
          grievance_id,
        });
        // console.log("Response of Comment ::", response?.status);

        getComments();

        if (response.status == "200") {
          // navigation("/admin")
          // console.log(">>>>>>>>>>>");
          // toast.success("Comment Added!", {
          //   position: "top-right",
          //   autoClose: 3000,
          //   hideProgressBar: true,
          // })
        }
      } catch (error) {
        console.error("Error fetching grievance data:", error);
      }
    };
    fetchData();
    resetForm({ grievance_id: "", comment: "", comment_by: "", })
  };

  // console.log(grievanceId,"id")

  const dataTwo = JSON.parse(localStorage.getItem("userDetails"));
  // console.log("MMMMMMMMMM", dataTwo?.description);
  const grievanceMessage = dataTwo?.description;


  const chnageStatus = (e) => {

    statusUpdate(e);

  }

  const columns = [
    // {
    //   id: 1,
    //   name: "Grievance Id",
    //   selector: (row) => row.grievance_id,
    //   sortable: true,
    //   reorder: true,
    // },
    // {
    //   id: 2,
    //   name: "Full Name",
    //   selector: (row) => row.full_name,
    //   sortable: true,
    //   reorder: true,
    // },
    {
      id: 1,
      name: "Tracking Number",
      selector: (row) => row.tracking_number,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Created At",
      selector: (row) => row.created_date,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Mobile Number",
      selector: (row) => row.mobile_number,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      id: 6,
      cell: (row) => {
        return (
          <>
            <div>
              <div
                className="px-0 py-0 bg-gray-50 text-blue-800 rounded hover:bg-gray-50 cursor-pointer "
                onClick={() => {
                  document.getElementById("my_modal_3").showModal();
                  handleRowClicked(row);
                }}
              >
                Description
              </div>
              <dialog id="my_modal_3" className="modal ">
                <div className="modal-box w-11/12 max-w-4xl bg-white ">
                  <form method="dialog">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 bg-white">
                      ✕
                    </button>
                  </form>

                  <h3 className="font-bold text-lg px-0 py-0 mt-0 bg-white">
                    Basic Details
                  </h3>

                  <div className="py-4 mb-2 bg-white">
                    <p>
                      Grievance Category : <strong>{grievanceCategory}</strong>
                    </p>
                    <p className="mt-2">
                      Grievance Message : <strong>{grievanceMessage}</strong>
                    </p>
                  </div>
                  <hr />

                  {/* <h3 onClick={()=>setGrievanceId(props.original.row)} className="font-bold text-lg px-5 py-0">Add Comment</h3> */}
                  {/* <p className="py-4">Press ESC key or click on ✕ button to close</p> */}
                  {/* <h3 className="font-bold text-lg px-0 py-0 mt-2">Add Comment</h3> */}

                  {/* Form is starts from here */}
                  <div className="mt-4 px-0 py-0 ">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ resetForm }) => (
                        <Form>
                          {/* <div className="mb-0">
                            <label
                              htmlFor="comment"
                              className="block text-gray-600 text-lg font-bold mb-1"
                            >
                              Comments
                            </label>
                            <Field
                              as="textarea"
                              id="comment"
                              name="comment"
                              className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-green-500"
                              rows="2" // Number of visible rows
                              placeholder="Add Comment"
                            />
                          </div> */}

                          <div className="grid grid-cols-1 gap-4 mb-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 ml-0 mr-0 text-center ">
                            <div className="px-4 py-2 rounded-lg bg-gray-100  ">
                              <div className="mb-0 ">
                                <label
                                  htmlFor="comment"
                                  className="block text-gray-600 text-lg font-bold mb-1  "
                                >
                                  Comments
                                </label>
                                <Field
                                  as="textarea"
                                  id="comment"
                                  name="comment"
                                  className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-green-500"
                                  rows="2" // Number of visible rows
                                  placeholder="Add Comment"
                                />
                              </div>
                              <div
                                className="flex items-center justify-between flex-wrap py-4 px-0 "
                                data-modal-target="staticModal"
                                data-modal-toggle="staticModal"
                              >
                                <button
                                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none"
                                  onClick={() =>
                                    resetForm({ values: initialValues })
                                  }
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none"
                                >
                                  Comment
                                </button>
                              </div>
                            </div>

                            <div className="px-4 py-3 rounded-lg bg-gray-100  ">
                              {/* <FilterDropdown /> */}
                              <label htmlFor="selectedOption" className="block text-gray-600 text-lg font-bold mb-1 ">
                                Change Status
                              </label>

                              <select
                                // defaultValue={"pending"}
                                defaultValue={""}
                                onChange={(e) => chnageStatus(e.target.value)}
                                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                              >
                                <option value="">Select Status</option>
                                <option value="pending">Pending</option>
                                <option value="process">Process</option>
                                <option value="resolved">Resolved</option>
                              </select>
                            </div>

                          </div>

                          {/* <div
                            className="flex items-center justify-between flex-wrap py-4 px-0"
                            data-modal-target="staticModal"
                            data-modal-toggle="staticModal"
                          >
                            <button
                              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none"
                              onClick={() =>
                                resetForm({ values: initialValues })
                              }
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none"
                            >
                              Comment
                            </button>
                          </div> */}
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <hr />

                  <h3 className="font-bold text-lg px-0 py-0 mb-2 bg-white">
                    Comments
                  </h3>
                  {/* <div className="bg-gray-500 px-4 py-3 text-white"> */}
                  <div className="rounded-lg py-1 ">
                    {comments.map((data) => {
                      return (
                        <>
                          <div className="px-4 py-3 bg-gray-400 rounded-lg ">
                            <p className="mt-0 font-semibold">{data.comment_date} ~ {data.comment_time} </p>
                            <div className="bg-gray-50 text-black rounded ">
                              <h1 className="px-4 py-2 mt-2 bg-gray-300 font-semibold rounded">{data.comment}</h1>
                              {/* <p className="px-4 py-1 mt-2">Comment Date :: <strong>{data.comment_date}</strong></p> */}
                            </div>
                          </div>
                          <hr className="mt-2" />
                        </>
                      );
                    })}
                  </div>
                </div>
              </dialog>
            </div>
          </>
        );
      },
    },
  ];
  const handleRowClicked = (row) => {
    setGrievanceId(row.grievance_id);
    setTrackingId(row.tracking_number);

    // Process the row data as needed
  };

  return (
    <>
      <div className="w-full ">
        <DataTable
          className="w-100"
          title="Grievance List"
          columns={columns}
          data={data}
          defaultSortFieldId={1}
          //   sortIcon={<SortIcon />}
          pagination
        />
      </div>

      <Paginataion
        dataCount={totalItems}
        pageSize={100}
        currentPage={currentPage}
        changeCurrentPage={changeCurrentPage}
      />
    </>
  );
};
export default GrievanceTable;
