import React from 'react';
import GrievanceTable from '../greivence_table';
import Help from './help';
import ManageContent from './ManageContent';

const MainContent = ({ selectedMenuItem }) => {
    const content = [
        <GrievanceTable/>,
        <ManageContent />,
        <Help/>,
    ];

    return (
        <div className="p-4 w-full overflow-auto">
            {content[selectedMenuItem]}
        </div>
    );
};

export default MainContent;
