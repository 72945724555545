import React, { useState } from "react";
import { useEffect } from "react";
import Dropdown from "../../components/common_components/Dropdown";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Arrow from "../../../src/assets/images/Arrow.svg";
import Nitesh from "../../../src/assets/images/Nitesh.png";
import { useTranslation } from "react-i18next";
import { apiVerifyAdhar } from "../../services/AdhaarServices";
import picTwo from "../../../src/assets/images/picTwo.png"
import { apiSendOtp } from "../../services/OtpService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../custom-hooks";



function VerifyAadhar() {

  const context = useAuth()


  const { t } = useTranslation();
  const navigation = useNavigate();




  const initialValues = {
    mobile_number: "",
    aadharNumber: "",
  };

  const validationSchema = Yup.object().shape({
    mobile_number: Yup.string()
      .matches(/^\d{10}$/, "Invalid phone number format (10 digits)"),
    // .required("Phone number is required"),

    aadharNumber: Yup.string()
      // .required("Aadhar number is required")
      .matches(/^\d{12}$/, "Aadhar number must be 12 digits"),
  });

  const handleSubmit = async (values) => {
    // console.log(">>>>>>>>>>>>>>>...", values);

    var { aadharNumber, mobile_number } = values;


    if (aadharNumber) {
      const data = {
        "adhaar_number": aadharNumber
      }

      try {
        const response = await apiVerifyAdhar(data);
        if (response.status == "200") {
          // console.log("running", response)
          localStorage.setItem("ad", JSON.stringify(aadharNumber));
          const signature = response?.data?.signatiure;
          const clientKey = response?.data?.client_key;
          window.location.replace(`https://app.attestr.com/dlauth#web?cl=${clientKey}&digest=${signature}&rurl=https://jdu.aapkaashok.com/welcome`)
        }
        // console.log(response);
      }
      catch (err) {
        console.log(err);
      }

    }
    else if (mobile_number) {
      const data = {
        otp_type: "Both",
        otp_for: "Grievence Submission",
        email: "pyexpert62@gmail.com"
      };
      // const userObject = JSON.parse(localStorage.getItem("userObj"));
      // // console.log("User object ::", userObject?.email);

      // const email = userObject?.email


      try {
        const response = await apiSendOtp({ mobile_number, ...data });
        // console.log(">>>>>>>>>>>New", response?.data?.verification_token);

        if (response.status == "200") {
          toast.success("OTP sent to the registered mobile no!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
          })
          const storagetoken = response?.data?.verification_token;
          // console.log("Response", response);
          localStorage.setItem("authToken", JSON.stringify(storagetoken));

          // history.push("/otp")
          context.login();
          navigation("/otp");
        }
      } catch (err) {
        console.log(err);
      }

    }
    else if (aadharNumber == "" && mobile_number == "") {
      toast.warning("Please select aadhar or mobile number verification process.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      })
    }



  };


  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="grid grid-cols-1 gap-0 mb-0 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 ml-0 mr-0 mt-0">
          <div className="px-0 py-0 bg-amber-300 mt-0 ">
            <div className="ml-1 mt-1 px-5 py-5">
              <Link to={"/"}>
                <img
                  src={Arrow}
                  alt="Something Went Wrong"
                  width={80}
                  height={60}
                />
              </Link>
            </div>
            <br />

            <div className="text-center mt-36 ml-0 mx-auto ">
              {/* <div className='flex justify-center items-center'>
                                <img src={Man} alt="Something Went Wrong" width={600} height={100} />
                            </div> */}
              {/* <div className="flex justify-left items-left">
                                <img
                                    src={picTwo}
                                    alt="Something Went Wrong"
                                    width={400}
                                    height={600}
                                />
                            </div> */}
              {/* <img src={picTwo} alt="Your Image" class="rounded-full w-100 h-80" /> */}
              {/* <img src={picTwo} alt="Your Image" class="rounded-full w-140 h-96" /> */}
              {/* <img src={picTwo} alt="Your Image" class="rounded-full w-full h-96 pr-20 " /> */}
              <img src={picTwo} alt="Your Image" class="rounded-full w-3/5 h-full ml-24" />


            </div>
          </div>

          <div className="px-8 py-6 bg-yellow-100 mt-0 text-black">
            <div class="flex justify-end ...">
              <div className="w-48">
                <Dropdown />
              </div>
            </div>

            <div className="mt-20 mb-0">
              {/* <div className='flex justify-center items-center'>
                                    <img src={Verify} alt="Error" />
                                </div><br /> */}
              <div className="text-center">
                <div className="font-semibold truncate text-2xl text-gray-800">
                  {/* {t('login_page')} */}

                  Verification

                  {/* {t('verification_phone')} */}

                  {/* {t('adhar_verification')} */}


                </div>
                {/* <div className="text-xl mt-1">
                  {t('login_admin_dashboard')}
                </div> */}
              </div>
            </div>



            <div className="px-28 py-0 mt-5">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="mb-3 mt-3">
                    <label
                      htmlFor="mobile_number"
                      className="block text-gray-600 text-sm font-medium mb-1"
                    >
                      {/* Enter Phone Number * */}
                      {t('enter_phone_number')}
                    </label>
                    <Field
                      type="tel" // Set the input type to "tel"
                      id="mobile_number"
                      name="mobile_number"
                      className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-black"
                      // placeholder="Enter phone number"
                      placeholder={t('placeholder_enter_phone_number')}


                    />
                    <ErrorMessage
                      name="mobile_number"
                      component="p"
                      className="text-red-500 text-sm mt-0"
                    />
                  </div>

                  <div className="text-center font-semibold text-2xl text-black">
                    {/* <h1>OR</h1> */}
                    <h1>{t('or')}</h1>

                  </div>

                  <div className="mb-8">
                    <label
                      htmlFor="aadharNumber"
                      className="block text-gray-600 text-sm font-medium mb-1"
                    >
                      {/* Enter Aadhar No * */}
                      {t('enter_aadhar_no')}

                    </label>
                    <Field
                      type="text"
                      id="aadharNumber"
                      name="aadharNumber"
                      className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-black"
                      // placeholder="Enter Aadhar No"
                      placeholder={t('placeholder_enter_aadhar_no')}

                    />
                    <ErrorMessage
                      name="aadharNumber"
                      component="div"
                      className="text-red-500 text-sm mt-0"

                    />
                  </div>


                  {/* <div className="font truncate text-sm text-center mt-4">
                                                Didn't receive the OTP?{" "}
                                                <span className="text-blue-500">Resend</span>
                                            </div> */}

                  {/* <Link to={"/welcome"} className="mt-8"> */}
                  <button
                    type="submit"
                    className="w-full px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-900 focus:outline-none"
                  >
                    {/* Verify */}
                    {t('verify_button_phone')}
                  </button>
                  {/* </Link> */}
                </Form>
              </Formik>
            </div>
          </div>
        </div>

        {/* Push footer to the bottom */}
        <div className="flex-grow"></div>

        {/* Footer Section */}
        {/* <footer className="bg-green-800 text-white py-0">
          <div className="container mx-auto text-center">
            &copy; 2023 Janta Dal(United). All Rights Reserved.
          </div>
        </footer> */}

        <div className="px-4 py-3 bg-gray-100 dark:bg-gray-700 md:flex md:items-center md:justify-between">
          <span className="text-sm text-gray-500 dark:text-gray-300 sm:text-center">© 2023 <a href="https://flowbite.com/">JDU(United)</a>. All Rights Reserved.
          </span>
          <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
            <a href="https://www.facebook.com/AshokChoudhaary/" target="_blank" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                <path fill="#3f51b5" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M29.368,24H26v12h-5V24h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H30v4h-2.287 C26.104,16,26,16.6,26,17.723V20h4L29.368,24z"></path>
              </svg>
            </a>
            <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FAshokChoudhaary" target="_blank" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                <path fill="#212121" fill-rule="evenodd" d="M38,42H10c-2.209,0-4-1.791-4-4V10c0-2.209,1.791-4,4-4h28	c2.209,0,4,1.791,4,4v28C42,40.209,40.209,42,38,42z" clip-rule="evenodd"></path><path fill="#fff" d="M34.257,34h-6.437L13.829,14h6.437L34.257,34z M28.587,32.304h2.563L19.499,15.696h-2.563 L28.587,32.304z"></path><polygon fill="#fff" points="15.866,34 23.069,25.656 22.127,24.407 13.823,34"></polygon><polygon fill="#fff" points="24.45,21.721 25.355,23.01 33.136,14 31.136,14"></polygon>
              </svg>
            </a>
            <a href="https://www.instagram.com/ashokchoudhaary/?hl=en" target="_blank" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"></stop><stop offset=".328" stop-color="#ff543f"></stop><stop offset=".348" stop-color="#fc5245"></stop><stop offset=".504" stop-color="#e64771"></stop><stop offset=".643" stop-color="#d53e91"></stop><stop offset=".761" stop-color="#cc39a4"></stop><stop offset=".841" stop-color="#c837ab"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"></stop><stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
              </svg>
            </a>
          </div>
        </div>

      </div>
    </>
  );
}

export default VerifyAadhar;
