import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import picOne from "../assets/images/picOne.jpg";
// import picFour from "../assets/images/picFour.jpg";
// import picThree from "../assets/images/picThree.jpg";


const ImageSlider1 = () => {
  const videoStyle = {
    width: '500px',
    height: '281px',
    
  };

  return (
    <Carousel >

      {/* <div>
        <img src={picOne} alt="Slide 1" />
      </div> */}


{/* <div>
  
  <video style={videoStyle}controls>
    <source
      src="https://jdu-aapkaashok.s3.ap-south-1.amazonaws.com/IMG_9375.MOV "
      type="video/mp4"
      
    />
    </video>
</div> */}



      {/* <div>
        <img src={picFour} alt="Slide 2" />
      </div>  */}
    
   
    <div>
  <video style={videoStyle} controls>
    <source
      src="https://jdu-aapkaashok.s3.ap-south-1.amazonaws.com/IMG_9377.MOV"
      type="video/mp4"
     

    />
    </video>
</div>

       {/* <div>
        <img src={picThree} alt="Slide 3" />
      </div> */}


{/* <div>
  <video style={videoStyle} controls>
    <source
      src="https://jdu-aapkaashok.s3.ap-south-1.amazonaws.com/IMG_9378.MOV"
      type="video/mp4"
      

    />
    </video>
</div> */}



      {/* <div className='grid grid-cols-3 gap-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3  2xl:grid-cols-3 ml-0 2xl:mr-24 text-center mt-0 mb-8 py-2'>
        <div>1</div>
        <div>1</div>
        <div>1</div>
      </div> */}


    </Carousel>
  );
};

export default ImageSlider1;





