import React from "react";
import Dropdown from "./Dropdown";
import Arrow from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Navbar() {
    const { t } = useTranslation();

    const userObject = JSON.parse(localStorage.getItem("userObj"));
    // console.log(userObject);

    return (
        <>
            {/* Manually Added */}
            {/* <div className="navbar dark:text-black"> */}
            <div className="navbar  text-black">
                <div className="navbar-start">
                    <div className="dropdown">
                        <label tabIndex={0} className="btn btn-ghost lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                        </label>
                        <div tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-44 ">
                            <dir className="dark:text-white">
                                {/* <Link to="/about_me" className="block mt-2 lg:inline-block lg:mt-0 mr-4 p-1"> */}
                                <Link
                                    to="/about_me"
                                    className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-lime-700 "
                                >
                                    {t("about_title")}
                                </Link>
                                <Link
                                    to="/achivements"
                                    className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-lime-700"
                                >
                                    {t("achievement_title")}
                                </Link>
                                <Link
                                    to="/progress_report"
                                    className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-lime-700"
                                >
                                    {t("progress_reports_title")}
                                </Link>
                                <Link
                                    to="/action_plans"
                                    className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-lime-700"
                                >
                                    {t("action_plans_title")}
                                </Link>
                                <Link
                                    to="/project_executed"
                                    className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-lime-700"
                                >
                                    {t("projects_executed_title")}
                                </Link>
                                <Link
                                    to="/new_updates"
                                    className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-lime-700"
                                >
                                    {t("new_updates_title")}
                                </Link>
                                <Link
                                    to="/track_report"
                                    // className="text-md px-2 py-1 w-32 ml-2 leading-none border rounded text-lime-700 border-lime-700 hover:border-transparent hover:text-slate-50 hover:bg-lime-700 mt-0 lg:mt-0 ml-0"
                                    className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-lime-700"
                                >
                                    {" "}
                                    {t("track_grievance_button")}
                                </Link>
                            </dir>
                        </div>
                    </div>
                    <a href="https://www.aapkaashok.com/" >
                        <img
                            src={Arrow}
                            alt="Something Went Wrong"
                            width={80}
                            height={60}
                            className="mx-2"
                        />
                    </a>
                </div>
                <div className="navbar-center hidden lg:flex">
                    {/* <ul className="menu menu-horizontal px-1">
                        <li><a>Item 1</a></li>
                        <li tabIndex={0}>
                            <details>
                                <summary>Parent</summary>
                                <ul className="p-2">
                                    <li><a>Submenu 1</a></li>
                                    <li><a>Submenu 2</a></li>
                                </ul>
                            </details>
                        </li>
                        <li><a>Item 3</a></li>
                    </ul> */}
                    <dir className="bg-yellow-100 px-4 py-2">
                        {/* <Link to="/about_me" className="block mt-2 lg:inline-block lg:mt-0 mr-4 p-1"> */}
                        <Link
                            to="/about_me"
                            className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-lime-700"
                        >
                            <strong>{t("about_title")}</strong>
                        </Link>
                        <Link
                            to="/achivements"
                            className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-lime-700"
                        >
                            <strong>{t("achievement_title")}</strong>
                        </Link>
                        <Link
                            to="/progress_report"
                            className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-lime-700"
                        >
                            <strong>{t("progress_reports_title")}</strong>
                        </Link>
                        <Link
                            to="/action_plans"
                            className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-lime-700"
                        >
                            <strong>{t("action_plans_title")}</strong>
                        </Link>
                        <Link
                            to="/project_executed"
                            className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-lime-700"
                        >
                            <strong>{t("projects_executed_title")}</strong>
                        </Link>
                        <Link
                            to="/new_updates"
                            className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-lime-700"
                        >
                            <strong>{t("new_updates_title")}</strong>
                        </Link>
                        <Link
                            to="/track_report"
                            className="text-md px-2 py-1 w-32 ml-2 leading-none border-2 rounded text-lime-700 border-lime-700 hover:border-transparent hover:text-slate-50 hover:bg-lime-700 mt-0 lg:mt-0 ml-0"
                        >
                            {" "}
                            <strong>{t("track_grievance_button")}</strong>
                        </Link>
                    </dir>
                </div>
                <div className="navbar-end">
                    <Dropdown />
                </div>
            </div>

        </>
    );
}

export default Navbar;
