import React from 'react'
import DataTable from 'react-data-table-component'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import picTwo from "../../../src/assets/images/picTwo.png"



function ManageContent() {


  const data = [
    { id: 1, name: 'Ashok Chaudhary', district: 'Siwan', status: 'Active', edit: '' },
    { id: 2, name: 'Rajiv Ranjan Singh', district: '	Araria', status: 'Active', edit: '' },
    { id: 3, name: 'Umesh Kushwaha', district: '	Arwal', status: 'Active', edit: '' },
    { id: 4, name: 'Santosh Kumar Kushwaha', district: '	Aurangabad', status: 'Active', edit: '' },
    { id: 5, name: 'Kushwaha Ramkumar Sharma', district: 'Banka', status: 'Active', edit: '' },
    { id: 6, name: 'Shri Bhagwan Singh Kushwaha', district: 'Begusarai', status: 'Active', edit: '' },
    { id: 7, name: 'Vijay Kumar Chaudhary', district: 'Bhagalpur', status: 'Active', edit: '' },
    { id: 8, name: 'Harivansh Narayan Singh', district: 'Buxar', status: 'Active', edit: '' },
    { id: 9, name: 'Bashistha Narain Singh', district: 'Darbhanga', status: 'Active', edit: '' },
    { id: 10, name: 'Ram Nath Thakur', district: '	East Champaran', status: 'Active', edit: '' },
  ];

  const columns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Name',
      selector: 'name',
    },
    {
      name: 'District',
      selector: 'district',
    },
    {
      name: 'Status',
      selector: 'status',
    },
    {
      name: 'Edit Profile',
      selector: 'edit',
      cell: (row) => {
        return (
          <>
            <div>
              <div
                className="px-0 py-0 bg-gray-50 text-blue-800 rounded hover:bg-gray-50 cursor-pointer  "
                onClick={() => {
                  document.getElementById("my_modal_3").showModal();
                  // handleRowClicked(row);
                }}
              >
                View Profile
              </div>
              <dialog id="my_modal_3" className="modal ">
                <div className="modal-box w-11/12 max-w-4xl bg-white ">
                  <form method="dialog">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 bg-white ">
                      ✕
                    </button>
                  </form>

                  <h3 className="font-bold text-lg px-0 py-0 mt-0 bg-white text-center">
                    Edit Profile
                  </h3>



                  <div className='bg-gray-600 px-4 py-2 rounded-lg text-white mt-2'>
                    <img src={picTwo} alt="Your Image" class="rounded-full w-1/5 h-full ml-0" />
                    <div className="py-1 mb-0 text-base">
                      <h3> Name : <strong>Ashok Choudhary</strong></h3>
                      <h3> From : <strong>Barbigha Sheikhpura</strong></h3>
                    </div>

                  </div>

                  <hr />


                  <div className="mt-4 px-0 py-0 ">
                    <Formik
                    // initialValues={initialValues}
                    // validationSchema={validationSchema}
                    // onSubmit={handleSubmit}
                    >
                      {({ resetForm }) => (
                        <Form>

                          <div className="grid grid-cols-1 gap-4 mb-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 ml-0 mr-0 ">

                            <div className="px-4 py-0 rounded-lg bg-gray-100  ">
                              <div className="mb-0 ">
                                <label
                                  htmlFor="comment"
                                  className="block text-gray-600 text-base font-bold mb-1  "
                                >
                                  About Us
                                </label>
                                <Field
                                  as="textarea"
                                  id="comment"
                                  name="comment"
                                  className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-green-500"
                                  rows="6" // Number of visible rows
                                  placeholder="About Us"
                                />
                              </div>

                            </div>

                            <div className="px-4 py-0 rounded-lg bg-gray-100  ">
                              <div className="mb-0 ">
                                <label
                                  htmlFor="comment"
                                  className="block text-gray-600 text-base font-bold mb-1  "
                                >
                                  Achievement
                                </label>
                                <Field
                                  as="textarea"
                                  id="comment"
                                  name="comment"
                                  className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-green-500"
                                  rows="6" // Number of visible rows
                                  placeholder="Achievement"
                                />
                              </div>

                            </div>

                            <div className="px-4 py-0 rounded-lg bg-gray-100  ">
                              <div className="mb-0 ">
                                <label
                                  htmlFor="comment"
                                  className="block text-gray-600 text-base font-bold mb-1  "
                                >
                                  Progress Report
                                </label>
                                <Field
                                  as="textarea"
                                  id="comment"
                                  name="comment"
                                  className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-green-500"
                                  rows="6" // Number of visible rows
                                  placeholder="Progress Report"
                                />
                              </div>

                            </div>

                            <div className="px-4 py-0 rounded-lg bg-gray-100  ">
                              <div className="mb-0 ">
                                <label
                                  htmlFor="comment"
                                  className="block text-gray-600 text-base font-bold mb-1  "
                                >
                                  Action Plans
                                </label>
                                <Field
                                  as="textarea"
                                  id="comment"
                                  name="comment"
                                  className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-green-500"
                                  rows="6" // Number of visible rows
                                  placeholder="Action Plans"
                                />
                              </div>

                            </div>

                            <div className="px-4 py-0 rounded-lg bg-gray-100  ">
                              <div className="mb-0 ">
                                <label
                                  htmlFor="comment"
                                  className="block text-gray-600 text-base font-bold mb-1  "
                                >
                                  Project Executed
                                </label>
                                <Field
                                  as="textarea"
                                  id="comment"
                                  name="comment"
                                  className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-green-500"
                                  rows="6" // Number of visible rows
                                  placeholder="Project Executed"
                                />
                              </div>

                            </div>

                            <div className="px-4 py-0 rounded-lg bg-gray-100  ">
                              <div className="mb-0 ">
                                <label
                                  htmlFor="comment"
                                  className="block text-gray-600 text-base font-bold mb-1  "
                                >
                                  New Updates
                                </label>
                                <Field
                                  as="textarea"
                                  id="comment"
                                  name="comment"
                                  className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-green-500"
                                  rows="6" // Number of visible rows
                                  placeholder="New Updates"
                                />
                              </div>

                            </div>



                          </div>

                          <div
                            className="flex items-center justify-between flex-wrap py-4 px-4"
                            data-modal-target="staticModal"
                            data-modal-toggle="staticModal"
                          >
                            <button
                              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-600 focus:outline-none"
                            // onClick={() =>
                            //   resetForm({ values: initialValues })
                            // }
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-600 focus:outline-none"
                            >
                              Change
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <hr />


                </div>
              </dialog>
            </div>
          </>
        );
      },
    },
  ];


  // const manageContent = async () => {
  //   try {

  //     //   const response = await apiManageContent();
  //     //   console.log("Data ::", response);
  //     //   setData(response?.data?.data);
  //     //   setTotalItems(response?.data?.count);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };


  return (
    <div className="w-full">
      <DataTable
        className='w-100'
        title="Manage Content "
        columns={columns}
        data={data}
        defaultSortFieldId={1}
        pagination
      />
    </div>
  )
}

export default ManageContent