import React from "react";
import Arrow from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Nav() {
  const { t } = useTranslation();

  const userObject = JSON.parse(localStorage.getItem("userObj"));
  // console.log(userObject);

  return (
    <>
      {/* Manually Added */}
      {/* <div className="navbar dark:text-black"> */}
      {/* <div className="navbar bg-lime-700 text-lg text-zinc-200 font-semibold"> */}
      <div className="navbar text-lg font-semibold">
        <div className="navbar-start bg-white  text-black">
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-ghost lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </label>
            <div
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow  rounded-box w-60 "
            >
              <dir>
                {/* <Link to="/about_me" className="block mt-2 lg:inline-block lg:mt-0 mr-4 p-1"> */}
                <Link
                  // to="/about_me"
                  className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-sky-400 "
                >
                  Home
                </Link>
                <Link
                  to="/?scroll=true1"
                  className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-sky-400"
                >
                  About
                </Link>
                <Link
                  to="/?scroll=true2"
                  className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-sky-400"
                >
                  Constituency
                </Link>
                <a
                  href="/help"
                  // className="text-md px-2 py-1 w-32 ml-2 leading-none border rounded text-lime-700 border-lime-700 hover:border-transparent hover:text-slate-50 hover:bg-sky-400 mt-0 lg:mt-0 ml-0"
                  className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-sky-400"
                >
                  Grievance
                </a>
              </dir>
            </div>
          </div>
        </div>
        <div className="navbar-center hidden lg:flex">
          {/* <ul className="menu menu-horizontal px-1">
                        <li><a>Item 1</a></li>
                        <li tabIndex={0}>
                            <details>
                                <summary>Parent</summary>
                                <ul className="p-2">
                                    <li><a>Submenu 1</a></li>
                                    <li><a>Submenu 2</a></li>
                                </ul>
                            </details>
                        </li>
                        <li><a>Item 3</a></li>
                    </ul> */}
          <dir>
            {/* <Link to="/about_me" className="block mt-2 lg:inline-block lg:mt-0 mr-4 p-1"> */}
            <Link
              // to="/about_me"
              className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-sky-400"
            >
              Home
            </Link>
            <Link
              to="/?scroll=true1"
              className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-sky-400"
            >
              About
            </Link>

            <Link
              to="/?scroll=true2"
              className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-sky-400"
            >
              Constituency
            </Link>

            <Link
              to="/help"
              className="block mt-2 lg:inline-block lg:mt-0 mr-0 px-2 py-1 rounded hover:border-transparent hover:text-slate-50 hover:bg-sky-400"
            >
              Grievance
            </Link>
          </dir>
        </div>
        <div className="navbar-end">
          {/* <Dropdown /> */}
          <a href="https://www.facebook.com/AshokChoudhaary/" target="_blank">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="32"
              fill="currentColor"
              className="bi bi-facebook ml-4"
              viewBox="0 0 16 16"
            >
              <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
            </svg> */}
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="44" height="44" viewBox="0 0 48 48">
              <path fill="#3f51b5" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M29.368,24H26v12h-5V24h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H30v4h-2.287 C26.104,16,26,16.6,26,17.723V20h4L29.368,24z"></path>
            </svg>
          </a>
          <a
            href="https://twitter.com/i/flow/login?redirect_after_login=%2FAshokChoudhaary"
            target="_blank"
          >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="32"
              fill="currentColor"
              className="bi bi-twitter-x ml-4"
              viewBox="0 0 16 16"
            >
              <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
            </svg> */}
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="44" height="44" viewBox="0 0 48 48">
              <path fill="#212121" fill-rule="evenodd" d="M38,42H10c-2.209,0-4-1.791-4-4V10c0-2.209,1.791-4,4-4h28	c2.209,0,4,1.791,4,4v28C42,40.209,40.209,42,38,42z" clip-rule="evenodd"></path><path fill="#fff" d="M34.257,34h-6.437L13.829,14h6.437L34.257,34z M28.587,32.304h2.563L19.499,15.696h-2.563 L28.587,32.304z"></path><polygon fill="#fff" points="15.866,34 23.069,25.656 22.127,24.407 13.823,34"></polygon><polygon fill="#fff" points="24.45,21.721 25.355,23.01 33.136,14 31.136,14"></polygon>
            </svg>
          </a>
          <a
            href="https://www.instagram.com/ashokchoudhaary/?hl=en"
            target="_blank"
          >
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="44" height="44" viewBox="0 0 48 48">
              <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"></stop><stop offset=".328" stop-color="#ff543f"></stop><stop offset=".348" stop-color="#fc5245"></stop><stop offset=".504" stop-color="#e64771"></stop><stop offset=".643" stop-color="#d53e91"></stop><stop offset=".761" stop-color="#cc39a4"></stop><stop offset=".841" stop-color="#c837ab"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"></stop><stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
            </svg>
          </a>
        </div>
      </div>
    </>
  );
}

export default Nav;
