import React from "react";
// import { Switch, Route } from 'react-router-dom'
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "../views/home-page/components/Home";
import Welcome from "../views/welcome-page/components/Welcome";
import Otp from "../views/otp-page/components/Otp";
import AboutMe from "../views/AboutMe";
import Achievement from "../views/Achievement";
import ProgressReport from "../views/progressReport";
import ActionPlans from "../views/actionPlans";
import ProjectExecuted from "../views/project-executed";
import NewUpdates from "../views/newUpdates";
import TrackGriennces from "../views/TrackGrienvces";
import ResponsePage from "../views/otp-page/components/ResponsePage";
import DashboardLayout from "../views/DashboardLayout";
import Login from "../views/login-page/components/Login";
import VerifyAadhar from "../views/VerifyAadhar/VerifyAadhar";
import ProtectedRoute from "./ProtectedRoutes";
import LandingPage from "../views/LandingPage/LandingPage";
import Nav from "../views/LandingPage/Nav";

const RoutesPage = () => {
  var isAuthenticated = false;
  const Navigation = useNavigate();
  return (
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/nav" element={<Nav />} />
      <Route exact path="/help" element={<Home />} />
      <Route path="/about_me" element={<AboutMe />} />
      <Route exact path="/achivements" element={<Achievement />} />
      <Route exact path="/progress_report" element={<ProgressReport />} />
      <Route exact path="/action_plans" element={<ActionPlans />} />
      <Route exact path="/project_executed" element={<ProjectExecuted />} />
      <Route exact path="/new_updates" element={<NewUpdates />} />
      <Route exact path="/track_report" element={<TrackGriennces />} />
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<DashboardLayout />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/responsepage" element={<ResponsePage />} />
        <Route path="/otp" element={<Otp />} />
        {/* Add other protected routes here */}
      </Route>
      {/* <Route path="/admin" element={<DashboardLayout />} /> */}

      <Route path="/verifyaadhar" element={<VerifyAadhar />} />
    </Routes>
  );
};

export default RoutesPage;
