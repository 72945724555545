import React, { useState } from "react";
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import MainContent from "./mainContent";

const App = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);

  const changeContent = (index) => {
    setSelectedMenuItem(index);
  };

  return (
    <div style={{background:"#EFF2FF"}} className="flex h-screen ">
      <div className="flex-1 flex flex-col overflow-hidden">
        <Navbar />
        <div className="flex flex-row h-screen">
          <Sidebar changeContent={changeContent} />
          <MainContent selectedMenuItem={selectedMenuItem} />
        </div>
      </div>
    </div>
  );
};

export default App;
