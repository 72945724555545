import { axiosInstance } from "./axiosInstance";
import appConfig from "../app.config";

let URL = `${appConfig.baseUrl}`

export async function apiVerifyOtp(data) {
    return axiosInstance.post(`${URL}/auth/verify_otp`, data);
}

export async function apiAddGrievance(data) {
    // console.log(data,'formdata')
    return axiosInstance.post(`${URL}/grievance/save`, data);
}