import React, { useEffect } from "react";
import Grievance from "../../../assets/images/Grievance.svg";
import Verify from "../../../assets/images/Grievance.svg";
import Redressal from "../../../assets/images/Redressal.svg";
import background from "../../../assets/images/background.svg";
import "../../../Home.css";
import Arrow from "../../../assets/images/Arrow.svg";
import Man from "../../../assets/images/Man.svg";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import picTwo from "../../../assets/images/picTwo.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { apiSendOtp } from "../../../services/OtpService";
import { apiGetGrievance } from "../../../services/DropdownApis";
import { apiGetDistrict } from "../../../services/DropdownApis";
import { apiGetArea } from "../../../services/DropdownApis";
import Nitesh from "../../../assets/images/Nitesh.png";
import { composeInitialProps, useTranslation } from "react-i18next";
import Dropdown from "../../../components/common_components/Dropdown";
import { toast } from "react-toastify";
import { apiVerifyAdharAuth } from "../../../services/AdhaarServices";
import { apiAddGrievance } from "../../../services/VerifyOtp";
import { useAuth } from "../../../custom-hooks";

function Welcome() {
  const context = useAuth();
  useEffect(() => {
    // Get the current URL's search query string
    const queryString = window.location.search;
    // console.log(queryString);

    // Create a URLSearchParams object from the query string
    const searchParams = new URLSearchParams(queryString);

    // Access individual query parameters
    const authId = searchParams.get("auth");
    const adharNum = localStorage.getItem("ad");

    // console.log(authId, adharNum)

    fetchAdharAuth(authId, adharNum);
  }, []);

  const fetchAdharAuth = async (authId, adharNum) => {
    const data = {
      auth: authId,
      adhaar_number: adharNum,
    };
    try {
      const response = await apiVerifyAdharAuth(data);
      // alert("Your Aadhar is now verified.")
      toast.success("Your Aadhar is now verified.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (err) {
      // alert("Something went wrong may be aadhar not verified.")
    }
  };
  const { t } = useTranslation();

  const [grievenceData, setGrievansce] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");

  // console.log("grievenceData", grievenceData);
  // console.log("districtData", districtData);
  // console.log("areaData", areaData);

  const navigation = useNavigate();

  const initialValues = {
    mobile_number: "",
    heading: "Electricity",
    description: "",
    email: "",
    constituency_id: "",
    state_id: "1",
    district_id: "",
    grievance_category: "",
    name: "",
    grievances_doc: null,
  };

  // console.log(initialValues.district);

  const validationSchema = Yup.object({
    mobile_number: Yup.string()
      .matches(/^\d{10}$/, "Invalid phone number format (10 digits)")
      .required("Mobile number is required"),
    description: Yup.string().required("Description is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    constituency_id: Yup.string().required("Area is required"),
    district_id: Yup.string().required("District is required"),
    // grievance_category: Yup.string().required("Grievance is required"),

    name: Yup.string().required("Name is required"),
    // file: Yup.mixed().required('File is required'),
  });

  useEffect(() => {
    // Define an async function to fetch grievance data
    const fetchGrievanceCategory = async () => {
      try {
        const response = await apiGetGrievance();
        // console.log("This is response", response?.data?.data);

        localStorage.setItem(
          "grievanceCategory",
          JSON.stringify(response?.data?.data)
        );

        setGrievansce(response?.data?.data);
      } catch (error) {
        console.error("Error fetching grievance data:", error);
      }
    };
    fetchGrievanceCategory();
  }, []);

  useEffect(() => {
    const fetchDistrict = async () => {
      try {
        const response = await apiGetDistrict(1);
        // console.log("This is district", response);

        setDistrictData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching grievance data:", error);
      }
    };
    fetchDistrict();
  }, []);

  useEffect(() => {
    fetchArea();
  }, [selectedDistrict]);

  const fetchArea = async () => {
    try {
      const response = await apiGetArea(selectedDistrict);

      setAreaData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching grievance data:", error);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const {
      mobile_number,
      heading,
      description,
      email,
      constituency_id,
      state_id,
      district_id,
      grievance_category,
      grievances_doc,
    } = values;
    console.log(values);
    // console.log("Form data ::", values);

    const formBody = {
      mobile_number,
      heading,
      description,
      email,
      constituency_id,
      state_id,
      district_id,
      grievance_category,
    };
    // console.log("formBody ::", formBody);

    // console.log("Image ::", grievances_doc);

    var bodyFormData = new FormData();
    bodyFormData.append("form_data", JSON.stringify(formBody));
    bodyFormData.append("grievances_doc", grievances_doc);

    try {
      const response = await apiAddGrievance(bodyFormData);
      if (response.status == "200") {
        context.login();
        navigation("/responsepage");
      }
    } catch (err) {
      // alert("Incorrect email or password.")
    }
    setSubmitting(false);
  };
  // const dynamicOptions = [
  //     { value: 'option1', label: 'Option 1' },
  //     { value: 'option2', label: 'Option 2' },
  //   ];

  return (
    <>
      {/* <div className='background_layout_one sm: w-1/2'></div> */}
      {/* <div className='bg-emerald-500 sm: w-1/2'>lorem100</div> */}

      <div className="flex flex-col min-h-screen">
        <div className="grid grid-cols-1 gap-0 mb-0 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 ml-0 mr-0 mt-0">
          {/* <div className="px-0 py-0 bg-gray-100 mt-0 background_layout_one "> */}
          <div className="px-0 py-0 bg-amber-300 mt-0 ">
            <div className="ml-1 mt-1 px-5 py-5">
              <Link to={"/"}>
                <img
                  src={Arrow}
                  alt="Something Went Wrong"
                  width={80}
                  height={60}
                />
              </Link>
            </div>
            <br />

            <div className="text-center mt-28 ml-0">
              {/* <div className='flex justify-center items-center'>
                                <img src={Man} alt="Something Went Wrong" width={600} height={100} />
                            </div> */}
              <div className="flex justify-left items-left mb-12">
                {/* <img
                                    src={picTwo}
                                    alt="Something Went Wrong"
                                    width={400}
                                    height={600}
                                /> */}
                {/* <img src={Nitesh} alt="Your Image" class="rounded-full w-full h-96 pr-20 " /> */}
                <img
                  src={picTwo}
                  alt="Your Image"
                  class="rounded-full w-full h-96 pr-20 "
                />
              </div>
            </div>
          </div>

          <div className="px-8 py-6 bg-yellow-100 mt-0 dark:text-black ">
            <div class="flex justify-end ...">
              <Dropdown />
            </div>

            <div className="">
              {/* <div className='flex justify-center items-center'>
                                    <img src={Verify} alt="Error" />
                                </div><br /> */}
              <div className="text-center mt-0">
                <div className="font-semibold truncate text-2xl text-gray-800">
                  {t("welcome_page")}
                </div>
                <div className="text-xl">{t("welcome_lodge")}</div>
              </div>
            </div>

            <div className="px-28 py-0 mt-2">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, setFieldValue }) => (
                  <Form>
                    <div className="mb-2">
                      <label
                        htmlFor="grievance_category"
                        className="block text-gray-600 text-sm font-medium mb-1"
                      >
                        {t("welcome_grievance_category")}
                      </label>

                      <Field
                        name="grievance_category"
                        className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        component="select"
                      >
                        <option
                          type="text"
                          className="form-control"
                          id="grievance_category"
                          value={""}
                        >
                          {t("option_select_grievance")}
                        </option>

                        {grievenceData?.map((item, i) => (
                          <option value={item.ID} key={i}>
                            {item.grievance_name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        x
                        name="grievance_category"
                        component="p"
                        className="text-red-500 text-sm mt-0"
                      />
                    </div>

                    <div className="mb-2">
                      <label
                        htmlFor="name"
                        className="block text-gray-600 text-sm font-medium mb-1"
                      >
                        {t("welcome_full_name")}
                      </label>
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        placeholder={t("placeholder_enter_name")}
                      />
                      <ErrorMessage
                        name="name"
                        component="p"
                        className="text-red-500 text-sm mt-0"
                      />
                    </div>

                    <div className="mb-2">
                      <label
                        htmlFor="mobile_number"
                        className="block text-gray-600 text-sm font-medium mb-1"
                      >
                        {t("welcome_mobile_number")}
                      </label>
                      <Field
                        type="tel" // Set the input type to "tel"
                        id="mobile_number"
                        name="mobile_number"
                        className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        placeholder={t("placeholder_mobile_number")}
                      />
                      <ErrorMessage
                        name="mobile_number"
                        component="p"
                        className="text-red-500 text-sm mt-0"
                      />
                    </div>

                    <div className="mb-2">
                      <label
                        htmlFor="email"
                        className="block text-gray-600 text-sm font-medium mb-1"
                      >
                        {t("welcome_email_id")}
                      </label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        placeholder={t("placeholder_enter_email_id")}
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        className="text-red-500 text-sm mt-0"
                      />
                    </div>

                    <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 mt-0 mb-2">
                      <div className="mb-0">
                        <label
                          htmlFor="district_id"
                          className="block text-gray-600 text-sm font-medium mb-1"
                        >
                          {t("welcome_select_district")}
                        </label>
                        <Field
                          as="select"
                          id="district_id"
                          name="district_id"
                          onClick={(e) => setSelectedDistrict(e.target.value)}
                          className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        >
                          <option value="">
                            {t("option_select_district")}
                          </option>
                          {districtData?.map((e, i) => (
                            <option value={e.district_id} key={i}>
                              {e.district_name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="district_id"
                          component="p"
                          className="text-red-500 text-sm mt-0"
                        />
                      </div>

                      <div className="mb-0">
                        <label
                          htmlFor="constituency_id"
                          className="block text-gray-600 text-sm font-medium mb-1"
                        >
                          {t("welcome_select_area")}
                        </label>
                        <Field
                          as="select"
                          id="constituency_id"
                          name="constituency_id"
                          className="w-full px-3 py-1 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        >
                          <option value="">{t("option_select_area")}</option>
                          {/* <option value="kolkata">Kolkata</option>
                                                <option value="delhi">Delhi</option> */}
                          {areaData?.map((e, i) => (
                            <option value={e.constituency_id} key={i}>
                              {e.constituency_name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="constituency_id"
                          component="p"
                          className="text-red-500 text-sm mt-0"
                        />
                      </div>
                    </div>
                    <div className="mb-2">
                      <label
                        htmlFor="description"
                        className="block text-gray-600 text-sm font-medium mb-1"
                      >
                        {t("welcome_message")}
                      </label>
                      <Field
                        as="textarea"
                        id="description"
                        name="description"
                        className="w-full px-3 py-3                                         
                                                border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        rows="2" // Number of visible rows
                        // placeholder='Write Message to Authority'
                        placeholder={t("message_write")}
                      />
                      <ErrorMessage
                        name="description"
                        component="p"
                        className="text-red-500 text-sm mt-0"
                      />
                    </div>

                    <div className="bg-light-blue-200 p-0 py-0 rounded-md">
                      {/* <label>Upload File</label> */}
                      <input
                        className="rounded text-sm font-semibold"
                        type="file"
                        name="grievances_doc"
                        id="grievances_doc"
                        onChange={(event) => {
                          setFieldValue(
                            "grievances_doc",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <ErrorMessage
                        name="grievances_doc"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="flex items-center justify-between flex-wrap mt-4">
                      {/* <Link to={"/otp"}> */}
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-900 focus:outline-none test-sm font-semibold"
                      >
                        {t("welcome_submit_button")}
                      </button>
                      {/* </Link> */}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* Push footer to the bottom */}
        {/* <div className="flex-grow"></div> */}

        {/* Footer Section */}
        {/* <footer className="bg-green-800 text-white py-0">
                    <div className="container mx-auto text-center">
                        &copy; 2023 Your Company. All Rights Reserved.
                    </div>
                </footer> */}

        <div className="px-4 py-3 bg-gray-100 dark:bg-gray-700 md:flex md:items-center md:justify-between">
          <span className="text-sm text-gray-500 dark:text-gray-300 sm:text-center">© 2023 <a href="https://flowbite.com/">JDU(United)</a>. All Rights Reserved.
          </span>
          <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
            <a href="https://www.facebook.com/AshokChoudhaary/" target="_blank" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                <path fill="#3f51b5" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M29.368,24H26v12h-5V24h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H30v4h-2.287 C26.104,16,26,16.6,26,17.723V20h4L29.368,24z"></path>
              </svg>
            </a>
            <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FAshokChoudhaary" target="_blank" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                <path fill="#212121" fill-rule="evenodd" d="M38,42H10c-2.209,0-4-1.791-4-4V10c0-2.209,1.791-4,4-4h28	c2.209,0,4,1.791,4,4v28C42,40.209,40.209,42,38,42z" clip-rule="evenodd"></path><path fill="#fff" d="M34.257,34h-6.437L13.829,14h6.437L34.257,34z M28.587,32.304h2.563L19.499,15.696h-2.563 L28.587,32.304z"></path><polygon fill="#fff" points="15.866,34 23.069,25.656 22.127,24.407 13.823,34"></polygon><polygon fill="#fff" points="24.45,21.721 25.355,23.01 33.136,14 31.136,14"></polygon>
              </svg>
            </a>
            <a href="https://www.instagram.com/ashokchoudhaary/?hl=en" target="_blank" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"></stop><stop offset=".328" stop-color="#ff543f"></stop><stop offset=".348" stop-color="#fc5245"></stop><stop offset=".504" stop-color="#e64771"></stop><stop offset=".643" stop-color="#d53e91"></stop><stop offset=".761" stop-color="#cc39a4"></stop><stop offset=".841" stop-color="#c837ab"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"></stop><stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;
