const appConfig = {
    apiPrefix: "/api",
    authenticatedEntryPath: "/home",
    unAuthenticatedEntryPath: "/sign-in",
    tourPath: "/",
    // baseUrl: "https://settlepaisainternalapi.sabpaisa.in/",
    baseUrl: "https://api.aapkaashok.com/janta_dal",
    enableMock: false,
};

export default appConfig;
