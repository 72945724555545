import { axiosInstance } from "./axiosInstance";
import appConfig from "../app.config";
let URL = `${appConfig.baseUrl}`;

export async function apiGetGrievanceList(id) {
  // console.log(">>>>>>>>>>>>>", data);
  return axiosInstance.get(
    `${URL}/grievances/get_district_grievances?district_id=${id}&page=1&page_size=100`
  );
}

export async function apiStatusUpdate(data) {
  // console.log(">>>>>>>>>>>>>", data);
  return axiosInstance.put(`${URL}/grievances/status_update`, data);
}
