import { axiosInstance } from "./axiosInstance";
import appConfig from "../app.config";

let URL = `${appConfig.baseUrl}`

export async function apiSendOtp(data) {
    // console.log(">>>>>>>>>>>>>", data);
    return axiosInstance.post(`${URL}/auth/send_otp`, data);
}

// {

//     "email":"pjr.pjr987@gmail.com",

//     "otp_type":"Both",

//     "otp_for":"Grievence Submission",

//     "mobile_number": "9700577873"

// }