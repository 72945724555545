import { axiosInstance } from "./axiosInstance";
import appConfig from "../app.config";
let URL = `${appConfig.baseUrl}`;

export async function apiAboutUs(id) {
    // console.log(">>>>>>>>>>>>>", data);
    return axiosInstance.get(
        `${URL}/navbar/about_us?login_id=1&constituency_id=1`
    );
}
export async function apiAchievments(id) {
    // console.log(">>>>>>>>>>>>>", data);
    return axiosInstance.get(
        `${URL}/navbar/achievments?login_id=1&constituency_id=1`
    );
}
export async function apiProgress(id) {
    // console.log(">>>>>>>>>>>>>", data);
    return axiosInstance.get(
        `${URL}/navbar/progress?login_id=1&constituency_id=1`
    );
}
export async function apiPorjectexecuted(id) {
    // console.log(">>>>>>>>>>>>>", data);
    return axiosInstance.get(
        `${URL}/navbar/Porjectexecuted?login_id=1&constituency_id=1`
    );
}
export async function apiActionPlans(id) {
    // console.log(">>>>>>>>>>>>>", data);
    return axiosInstance.get(
        `${URL}/navbar/Action_plans?login_id=1&constituency_id=1`
    );
}
export async function apiUpdate(id) {
    // console.log(">>>>>>>>>>>>>", data);
    return axiosInstance.get(
        `${URL}/navbar/update?login_id=1&constituency_id=1`
    );
}

